import { EsgNameEnum } from "./esg.enum";

export enum FormTypeEnum {
  Initial = 1,
  Complete = 2
}

export const TopicValues: { [key: number]: EsgNameEnum } = {
  0: EsgNameEnum.COMPANY,
  1: EsgNameEnum.ENVIRONMENTAL,
  2: EsgNameEnum.SOCIAL,
  3: EsgNameEnum.GOVERNANCE
};

export enum CompanySizeEnum {
  MICRO = 'MICRO',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE'
}

export enum CompanySizeNumberEnum {
  MICRO,
  SMALL,
  MEDIUM,
  LARGE
}

export enum EsgLevelEnum {
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
  IMPLEMENTING = 'IMPLEMENTING',
  NON_RESULTS = 'NON_RESULTS',
  CONSISTENT_RESULTS = 'CONSISTENT_RESULTS',
  REFERENCE_COMPANY = 'REFERENCE_COMPANY'
}

export enum EsgLevelNumberEnum {
  NOT_IMPLEMENTED,
  IMPLEMENTING,
  NON_RESULTS,
  CONSISTENT_RESULTS,
  REFERENCE_COMPANY
}

export enum CompanyLegalModelEnum {
  PRIVATE_COMPANY = 'PRIVATE_COMPANY',
  OUTSOURCED = 'OUTSOURCED',
  COOPERATIVE = 'COOPERATIVE',
  PUBLIC_INSTITUTION = 'PUBLIC_INSTITUTION',
  OTHERS = 'OTHERS'
}

export enum CompanyLegalModelNumberEnum {
  PRIVATE_COMPANY,
  NON_PROFIT_ORGANIZATION,
  COOPERATIVE,
  PUBLIC_INSTITUTION
}

export enum CompanySegmentEnum {
  AGRICULTURE = 'AGRICULTURE',
  FOOD_AND_BEVERAGES = 'FOOD_AND_BEVERAGES',
  FINANCIAL_SECTOR = 'FINANCIAL_SECTOR',
  CAPITAL_GOODS = 'CAPITAL_GOODS',
  EDUCATION = 'EDUCATION',
  ELECTRIC_SECTOR = 'ELECTRIC_SECTOR',
  REAL_ESTATE_AND_SHOPPING = 'REAL_ESTATE_AND_SHOPPING',
  MINING_AND_STEEL = 'MINING_AND_STEEL',
  PAPER_AND_PULP = 'PAPER_AND_PULP',
  OIL_AND_GAS = 'OIL_AND_GAS',
  HEALTHCARE = 'HEALTHCARE',
  TRANSPORTATION = 'TRANSPORTATION',
  RETAIL = 'RETAIL',
  TECHNOLOGY_AND_TELECOMMUNICATIONS = 'TECHNOLOGY_AND_TELECOMMUNICATIONS',
  OTHERCAPITAL = 'OTHERCAPITAL'
}

export enum CompanyEmployeeCountEnum {
  ZERO_TO_TEN = 'ZERO_TO_TEN',
  ELEVEN_TO_FIFTY = 'ELEVEN_TO_FIFTY',
  FIFTY_ONE_TO_TWO_HUNDRED = 'FIFTY_ONE_TO_TWO_HUNDRED',
  TWO_HUNDRED_AND_ONE_TO_FIVE_HUNDRED = 'TWO_HUNDRED_AND_ONE_TO_FIVE_HUNDRED',
  FIVE_HUNDRED_AND_ONE_TO_ONE_THOUSAND = 'FIVE_HUNDRED_AND_ONE_TO_ONE_THOUSAND',
  MORE_THAN_ONE_THOUSAND = 'MORE_THAN_ONE_THOUSAND'
}

export enum CompanyEmployeeCountNumberEnum {
  ZERO_TO_TEN,
  ELEVEN_TO_FIFTY,
  FIFTY_ONE_TO_TWO_HUNDRED,
  TWO_HUNDRED_AND_ONE_TO_FIVE_HUNDRED,
  FIVE_HUNDRED_AND_ONE_TO_ONE_THOUSAND,
  MORE_THAN_ONE_THOUSAND
}
export { EsgNameEnum as TopicEnum };

